<template>
  <el-main class="center-container-MT">
    <div class="center-layout-MT">
      <div class="body-title-layout" style="padding-left: 0">
        <div class="divider-3"></div>
        <div class="body-preview-title-content-style">测试历史</div>
      </div>
      <el-scrollbar class="record-items-container">
        <div class="record-item-container"
             v-for="(i,index) in testHistory"
             :key="index"
             :style="index===0?'margin-top:0;':'margin-top: 25px;'">
          <div class="time-record-layout">
            <div class="origin-style-TR"></div>
            <div class="time-content-TR">{{ i.time }}</div>
          </div>
          <div class="test-record-Info-container">
            <div class="score-layout-TRI">
              <span class="score-style">{{ i.score }}</span><span style="color: #B4B4B4;">分</span>
            </div>
            <div class="test-problem-layout-TRI">
              <span class="type-TR-TRI">{{ i.type }}</span><br>
              <span class="content-TR-TRI">{{ i.content }}</span>
            </div>
          </div>
        </div>
      </el-scrollbar>
    </div>
  </el-main>
</template>

<script>
export default {
  name: "MyTest",
  data() {
    const testHistory = [
      {
        id: 0,
        score: 86,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 1,
        score: 90,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 2,
        score: 82,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 3,
        score: 50,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 4,
        score: 98,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 5,
        score: 80,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      }, {
        id: 6,
        score: 86,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 7,
        score: 93,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 8,
        score: 88,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 9,
        score: 87,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 11,
        score: 85,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 12,
        score: 86,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 13,
        score: 73,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 14,
        score: 85,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
      {
        id: 15,
        score: 99,
        time: '2020/08/11 16:38',
        type: '中国数字化可视人体数据库第一套仿真试题',
        content: '盆会阴：1、诊断xxxx    2、解剖学问题xxxxxx',
      },
    ]
    return {
      testHistory,
    }
  }
}
</script>

<style>
</style>
