<template>
  <div class="main-container-PL">
<!--    <Header :personalInfo="false"></Header>-->
<!--    <div class="main-container" style="width: 1360px">-->
<!--      <personal-left></personal-left>-->
<!--      <LoginHistory v-if="isPersonalCenterShow"></LoginHistory>-->
<!--      <MyTest v-if="isMyTestShow"></MyTest>-->
<!--      <ExamDB v-if="isAnswerShow"></ExamDB>-->
<!--    </div>-->
  </div>
</template>
<script>
import Header from "@/components/Header";
import iconMT from "@/assets/img/personal_center/ceshi.png"
import icon2MT from "@/assets/img/personal_center/ceshi2.png"
import userIcon from "@/assets/img/personal_center/user_icon.png"
import user2Icon from "@/assets/img/personal_center/user_icon2.png"
import examIcon from "@/assets/img/personal_center/tiku.png"
import exam2Icon from "@/assets/img/personal_center/tiku2.png"
import ExamDB from "@/components/ExamDB";
import LoginHistory from "@/components/LoginHistory";
import MyTest from "@/components/MyTest";
import Answer from "@/view/Answer";
import PersonalLeft from "@/components/PersonalLeft"

export default {
  name: "PersonalCenter",
  components: {
    ExamDB,
    MyTest,
    LoginHistory,
    Answer,
    Header,
    PersonalLeft
  },
  data() {
    return {
      isPersonalCenterShow: true,
      isMyTestShow: false,
      isAnswerShow: false
    }
  },
  methods: {
    switchStyle(index) {
      // 个人中心组件
      let elem11 = document.getElementById('iconLH')
      let elem12 = document.getElementById('LH_word')
      //  我的测试组件
      let elem21 = document.getElementById('MTCmpIcon')
      let elem22 = document.getElementById('answerCmpWord')
      //  考试题库组件
      let elem31 = document.getElementById('examDBIcon')
      let elem32 = document.getElementById('examDBWord')

      let div1 = document.getElementById('clickLH')
      let div2 = document.getElementById('clickMT')
      let div3 = document.getElementById('clickED')
      if (index === 1) {
        div1.style.background = "#fff"
        div2.style.background = "none"
        div3.style.background = "none"
        elem11.src = userIcon
        elem21.src = iconMT
        elem31.src = exam2Icon
        elem12.style.color = '#089BAB'
        elem22.style.color = '#B7E1E6'
        elem32.style.color = '#B7E1E6'
        this.isPersonalCenterShow = true
        this.isAnswerShow = false
        this.isMyTestShow = false
      } else if (index === 2) {
        div2.style.background = "#fff"
        div1.style.background = "none"
        div3.style.background = "none"
        elem11.src = user2Icon
        elem21.src = icon2MT
        elem31.src = exam2Icon
        elem12.style.color = '#B7E1E6'
        elem22.style.color = '#089BAB'
        elem32.style.color = '#B7E1E6'
        this.isPersonalCenterShow = false
        this.isAnswerShow = false
        this.isMyTestShow = true
      } else {
        div3.style.background = "#fff"
        div2.style.background = "none"
        div1.style.background = "none"
        elem11.src = user2Icon
        elem21.src = iconMT
        elem31.src = examIcon
        elem12.style.color = '#B7E1E6'
        elem22.style.color = '#B7E1E6'
        elem32.style.color = '#089BAB'
        this.isPersonalCenterShow = false
        this.isAnswerShow = true
        this.isMyTestShow = false
      }
    },
  },
  watch: {
    $route(to, from) {
      if (this.$route.query.isAnswerShow) {
        this.switchStyle(3)
      } else if (this.$route.query.isPSShow) {
        this.switchStyle(1)
      } else {
        this.switchStyle(2)
      }
    }
  },
  mounted() {
    if (this.$route.query.isPSShow === 'true') {
      this.switchStyle(1)
    } else if (this.$route.query.isAnswerShow === 'true') {
      this.switchStyle(3)
    }
  }
}
</script>

<style>
</style>
